import React, { Dispatch, SetStateAction } from "react";
import { Button, Typography, Box, CircularProgress } from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import IAuth from "../../../types/IAuth";
import { Link } from "react-router-dom";
import { R_REMIND } from "../../../config/routes";
import { useTranslation } from "react-i18next";

export default function LoginForm({
  onSubmit,
  data,
  setData,
  setLoading,
  loading
}: {
  onSubmit: (data: IAuth) => void;
  data: IAuth;
  setData: Dispatch<SetStateAction<IAuth>> | Dispatch<SetStateAction<IAuth | undefined>>;
  setLoading: (loading: boolean) => void;
  loading: boolean;
}) {
  const { t } = useTranslation();

  function handleChange(event: React.FormEvent<any>) {
    setData({
      ...data,
      [event.currentTarget.name]: event.currentTarget.value
    });
  }

  function handleSubmit(a: React.FormEvent) {
    onSubmit({ ...data });
    setLoading(true);
  }

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <Box sx={{ mb: 1 }}>
        <Typography color="textPrimary" variant="h6" align="center">
          {t("Zaloguj się")}
        </Typography>
      </Box>
      <TextValidator
        label={t("Adres e-mail")}
        onChange={handleChange}
        name="email"
        placeholder={t("Wpisz adres e-mail")}
        variant="outlined"
        margin="normal"
        value={data.email || ""}
        validators={["required", "isEmail"]}
        errorMessages={[t("Pole jest wymaganie"), t("Adres e-mail jest niepoprawny")]}
        fullWidth
      />
      <TextValidator
        label={t("Hasło")}
        onChange={handleChange}
        name="password"
        type="password"
        placeholder={t("Wpisz hasło")}
        margin="normal"
        variant="outlined"
        value={data.password || ""}
        validators={["required"]}
        errorMessages={[t("Pole jest wymaganie")]}
        fullWidth
      />
      <Box sx={{ py: 2, position: "relative" }}>
        <Button
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={loading}
        >
          {t("Zaloguj się")}
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px"
            }}
          />
        )}
      </Box>
      <Typography color="textSecondary" variant="body1" align="left">
        {t("Nie pamiętasz hasła?")}
        <Link
          to={R_REMIND}
          style={{
            cursor: "pointer",
            textDecoration: "none",
            paddingLeft: ".5rem"
          }}
        >
          {t("Przypomnij")}
        </Link>
      </Typography>
    </ValidatorForm>
  );
}
