import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Slider, Typography } from "@mui/material";
import ICardGroupItem from "../../../types/ICardGroupItem";
import { styled } from "@mui/material/styles";
import { getLanguage } from "../../../utils/languagePicker";
import TrainerRideGroupItemComment from "./TrainerRideGroupItemComment";

const PrettoSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.light,
  height: 6,
  borderRadius: theme.shape.borderRadius,
  "& .MuiSlider-track": {
    border: "none"
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.common.white,
    border: "1px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: `0px 0px 10px 6px ${theme.palette.primary.light}33`
    },
    "&:before": {
      display: "none"
    }
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1,
    fontSize: "1rem",
    background: "unset",
    padding: 0,
    width: 36,
    height: 36,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: theme.palette.primary.light,
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)"
    },
    "& > *": {
      transform: "rotate(45deg)"
    }
  }
}));

const TrainerRideGroupItem = ({
  item,
  points,
  onPoint,
  comment,
  onComment
}: {
  item: ICardGroupItem;
  points: number;
  onPoint: (points: number) => void;
  comment: string;
  onComment: (comment: string) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const [value, setValue] = useState<number>(points);

  useEffect(() => {
    const pointsToSet = points ?? Math.round(item.points / 2);
    setValue(pointsToSet);
    onPoint(pointsToSet);
  }, [points]);

  return (
    <Grid container spacing={2} p={2}>
      <Grid item md={8} xs={12}>
        <Typography variant="body1">{item.name[getLanguage()]}</Typography>
      </Grid>
      <Grid item md={2} xs={8} display={"flex"} alignItems={"center"}>
        <PrettoSlider
          valueLabelDisplay="auto"
          aria-label={t("Ocena")}
          track={false}
          step={1}
          marks
          min={0}
          max={item.points}
          value={value ?? Math.round(item.points / 2)}
          onChange={(event, value) => {
            setValue(value as number);
          }}
          onChangeCommitted={(event, value) => {
            onPoint(value as number);
          }}
        />
      </Grid>
      <Grid item md={2} xs={4} display={"flex"} alignItems={"center"}>
        <TrainerRideGroupItemComment comment={comment} onChange={onComment} />
      </Grid>
    </Grid>
  );
};

export default TrainerRideGroupItem;
